@import '../common/base.scss';
@import url('../../../node_modules/bootstrap/dist/css/bootstrap.min.css');

@media (min-width:1300px) {
  .container {max-width: 1280px;}
}
 
* {
    box-sizing: border-box;
    font-family: $default-font;
    color:$base-color;
    word-break: break-word;
    white-space: normal;
    -webkit-font-smoothing: antialiased;
	 -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: $default-font;
  height: 100%;  
  font-weight: 400;
}
html{
  overflow-y: hidden;
}
body{
  overflow-y: auto!important;
}
iframe {
  border: none;
  outline:0!important
}
h6 {
  color: $base-color;
}
h1,h2,h3,h4,h5 {
  color: $base-color; 
  font-weight:700;
}
h1{
    font-size: 2.4rem;
    @media (max-width:1400px) {
       font-size: 2.2rem;
    }
}
h2{
    font-size: 1.9rem;
    @media (max-width:1400px) {
       font-size: 1.7rem;
    }
}
h3{
    font-size: 1.875rem; 
    @media (max-width:1400px) {
       font-size: 1.625rem;
    }    
}
h4{
    font-size: 1.5rem;  
    font-weight:600;
    @media (max-width:767px){
      font-size: 1.375rem;  
    }      
}
h5{
    font-size: 1.15rem;
    font-weight:600;
}
h6{
    font-size: 1rem;
}
.shadow {
    box-shadow: 0 0.1rem .7rem .1rem rgba(0, 0, 0, .15) !important;
}
ul, li {
  margin: 0;
  padding:0;
  list-style: none;
}  
a {
  font-size: 15px;
  line-height: 1.4;
  color: $base-color; 
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  outline: none !important;
  &:hover {
    text-decoration: none;
    color: $primary-color;
  }  
}
.link_color {
  cursor: pointer;
  color:$primary-color;
  white-space: nowrap;
}
p {
  font-size: $f16;
  color: $base-color; 
  line-height: 1.3;
  word-break: break-word;
  white-space: normal;
}
button {
  outline: none !important;
  cursor: pointer;
}
input, textarea {
  outline: none;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
textarea {
  outline: none;
  border: none;
  min-height: 140px;
  padding: .675rem .75rem;
}
.form-control {
  font-size: 1rem;
  border-radius: 40px;
  padding: 5px 20px;
  border:1px solid $grey-color;
  color: $base-color;
  line-height: 1;  
  min-height: 48px;
  background: $white-color; 
  cursor: default;
  @media(max-width: 768px){
    // font-size: 0.8rem;
  }
}

textarea.form-control {
  min-height:112px;
  padding-top: 12px;
  border-radius: 30px;
}
label {
  font-weight: 500;
  margin-bottom:0;
}

.border {
  border-color:$base-color!important;
}
.rounded {
  border-radius: 10px !important;
}

.btn-transparent {
  background: transparent;
  color:$grey-color2;
  font-weight: normal;
  font-weight: 600; 
  line-height: 1.2;
}

.btn-primary.focus, .btn-primary:focus, 
.btn-primary:not(:disabled):not(.disabled).active, 
.btn-primary:not(:disabled):not(.disabled):active, 
.show>.btn-primary.dropdown-toggle{
  background: $primary-color!important;
  border-color: $primary-color!important;
}

.btn-primary.disabled, .btn-primary:disabled {
  background: rgb(121, 184, 232);
  border-color: rgb(127, 186, 232);
}
.btn-outline-primary:not(:disabled):not(.disabled).active, 
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.show > .btn-outline-primary.dropdown-toggle {
  background: $primary-disable-color;
  border-color: $primary-disable-color;
  color:$black-color;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,.btn-primary.focus, 
.btn-primary:focus,.form-control:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-primary.dropdown-toggle:focus{
  box-shadow: none !important;
  border-color: #707070
}

.btn { 
  text-align: center; 
}
.btn-sm {
    min-width:72px;
    font-size: $f14;
    padding:8px 14px;
    font-weight: 600;
    border-radius: 20px;    
    min-height: 38px; 
} 
.btn-md {
    min-width:140px;
    font-size: $f16;
    padding:8px 16px;
    font-weight: 600;
    border-radius: 8px;
    @media (max-width:767px){
      padding:7px 14px;
      min-width:130px;
    }
}
.btn-lg {
    min-width:166px;
    font-size: $f16+3;
    padding: 9px 16px 10px;
    font-weight: 600;    
    border-radius: 10px;
    @media (max-width:1400px){
      font-size: $f16+1;
      padding: 8px 16px 9px;
      min-width:160px;
    }
    @media (max-width:1200px){
      min-width:130px;
      font-size: $f16;
    }
}
.btn-primary {
  background: $primary-color;
  border: 1px solid $primary-color;
  color: $white-color !important;
  &:hover, &:focus {
    background: $primary-dark;
    border: 1px solid $primary-dark;  
  }
} 
.btn-secondary {
  background: $secondary-color;
  border: 0px solid $grey-color;
  color: $white-color;
}
.btn-cancel {
  background: $grey-light-color;
  color: $black-color;
}
.btn-outline-white {
  background: transparent;
  border: 2px solid $white-color;
  color: $white-color;  
  &:hover, &:focus {
     background: $white-color;
     color: $base-color;  
  }
}
.btn-back {
  border:2px solid #E4DC06;
  background: $purple-color;
  color:$white-color;
  border-radius: 0;
  font-weight: 700;
  &:hover, &:focus {
    color:$white-color;
  }
}
.btn-outline-grey {
  border-color:$grey-color2;
  border-radius:25px;

  &:hover {
    background-color: $grey-color2;
    color:$white-color;
  }
}
.btn-outline-blue {
  border-color:$primary-color;
  border-radius:25px;
  color:$primary-color;
  &:hover {
    background-color: $primary-color;
    color:$white-color;
  }
}
.btn-default {
    background: $base-color;
    color: $white-color;
   &:hover, &:focus {
     background: $grey-color;
     color: $white-color;  
  }
}
textarea {
  outline: none;
  border: none;
  min-height: 140px;
  padding: .675rem .75rem;
}

.error {
  font-size: $f16;
  display: inline;
  left:0;
  position: $absolute;
  line-height: 14px;
}
input:placeholder {
  color: $base-color!important;
  opacity:1!important;
}
input::-webkit-input-placeholder{
  color: $base-color!important;
  font-family: $default-font;
  opacity:1!important;
}
input:-moz-placeholder{
  color: $base-color;
  opacity:1!important;
}
input:-ms-input-placeholder {
  color: $base-color!important;
  opacity:1!important;
}

textarea::-webkit-input-placeholder {
  color: $base-color;
}
textarea:-moz-placeholder {
  color: $base-color;
}
textarea::-moz-placeholder {
  color: $base-color;
}
textarea:-ms-input-placeholder {
  color: $base-color;
}

hr {
  border-top:1px solid $grey-color;  
  width:100%
}

.error_msg {
    font-size: 13px;
    line-height: 16px;
    padding-left: 21px;
    display: $inline-block; 
}
.css-1pahdxg-control {
  box-shadow: none!important;
}

.form-group {
  position: relative;
}
.form-control[readonly] {
  background: $white-color;
}

// Float Input label
.MuiFormControl-root {
  width:100%;
}
label + .MuiInput-formControl {
  padding:0;
  margin-top: 0!important;
}
.form-group {
  .MuiInputLabel-formControl {
    background: $white-color;
    color:$base-color;
    display: inline-block;
    font-size: 1rem; 
    border-radius: 7px;
    margin:0;
    font-weight: 400;
    padding: 1.8px 0px;
    transform: translate(20px, 14px) scale(1);
    z-index:1;
    @media(max-width: 575px){
      // font-size: 0.8rem; 
      padding: 0px;
    }
  }
  .select__value-container, .custom_drop input {
    color:$base-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100% - 8px);
  }
.drop-down {
  .select__value-container{
    margin-right: 28px;
    }    
  }
  .select__value-container.select__value-container--has-value {
  overflow: visible;
  }
  .select__placeholder, .css-1v9zv7g-placeholder {
    color:$base-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:max-content;
    // width: calc(100% - 20px);
    @media(max-width: 575px){
      top: 48%;
      -webkit-transform: translateY(-48%);
      -ms-transform: translateY(-48%);
      transform: translateY(-48%);
    }
  }
  .MuiFormControl-root{
    label{
      color:$base-color;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 30px);
    }
  }
  .MuiInputLabel-shrink {
    transform: translate(20px, -10px) scale(0.899);  
    width: auto !important;     
  }
  .MuiFormLabel-root.Mui-focused {
    color:$base-color;    
  }
}
.form-group{
  .MuiFormLabel-root{
    font-family: inherit;
    &.Mui-disabled {
      color:$base-color;
    }
  }
}
.select__value-container--has-value{
  .select__placeholder {
    top: -4px !important; 
    white-space:nowrap;
    background: $white-color;
    line-height: 14px;
    color:$base-color!important;
    padding: 1px 0px; 
  }
}
.css-4id1ot-placeholder {
    top: -8px !important;
    background: $white-color;
}

.label_float .float_label {
  top: -7px;
}
.float_label{
  position:$absolute;
  top:-10px;
  left:22px;
  background: $white-color;
  padding:0 0px;
  display: $inline-block;
  font-size: $f14;
  font-weight:400!important;
   z-index: 1;
  transition:all .5s ease-in-out;
}
.label_float {
  .MuiInputLabel-shrink {
    transform: translate(20px, -9px) scale(0.899);
  }
}
.css-26l3qy-menu, .select__menu {
  z-index: 11!important;
}
.radio {
    margin: 0.5rem 0;
    input{
      margin: auto;
      font-size: inherit;
      padding: 0 15px;
    }
    h5{
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .card_type{
      margin-right: 20px;
    }
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + .radio-label {
        &:before {
          content: '';
          background: #fff;
          border-radius: 100%;
          border: 2px solid #3197EE;
          border-radius: 50%;
          display: inline-block;
          width: 1.4em;
          height: 1.4em;
          position: relative;
          top: 0em;
          margin-right: 0.5em; 
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: #3197EE;
            box-shadow: inset 0 0 0 4px #f4f4f4;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            outline: none;
            border-color: #3197EE;
          }
        }
      }
        + .radio-label {
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }
    label{
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
  }
.form-group{
    label.float_label.floating_label{
    display: flex !important;
  }
 
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: transparent !important;
}

.MuiTypography-body1{
  font-family: inherit !important;
}
.driver_licence_radio{
  .MuiIconButton-label{
    svg.MuiSvgIcon-root{
      width: 1.4em;
      height: 1.4em;
      font-size: 1.2rem;
      path{
        color: #3197EE;
      }
}
}
}

