@import '../../../../../theme/common/base.scss';
.bg_coming {
    &:before {
        content: '';        
        background: $primary-color;
        background: -moz-linear-gradient(top,  #0091ff 0%, #35abff 100%);
        background: -webkit-linear-gradient(top,  #0091ff 0%,#35abff 100%); 
        background: linear-gradient(to bottom,  #0091ff 0%,#35abff 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0091ff', endColorstr='#35abff',GradientType=0 );
        position: fixed;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index:-1
    }
}
.comming-soon{
    position: relative;
    z-index: 11;
    @media (min-width:768px){
        position: absolute;
        width:100%;
        top:50%;
        transform: translateY(-55%);
        -webkit-transform: translateY(-55%);
    }    
    @media (max-width:767px){
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        -webkit-transform: translateY(-55%);
        left: 0;
        right: 0;
        text-align: center;
    }
    h2 {
        text-transform: uppercase;
        color:$white-color;
        font-weight: 600;
        font-size: 2.8rem;
        letter-spacing: 1.5px;
        margin:0 0 2.3rem;        
        @media (max-width:767px){ 
            font-size: 2.5rem;
        }
    }
    .form-group {
        max-width: 60%; 
        text-align: left;
        margin:0 auto 20px;
        .MuiFormLabel-root{
            font-family: inherit;
        }
    }
    .btn {
        background: transparent;
        border: 2px solid $white-color;
        color: $white-color;  
       
        &:hover, &:focus {
            background: $white-color;
            color: $base-color;  
        }
    }
}