.overlay {background: rgba(0,0,0,.7); position: fixed; top:0; left:0; right:0; height: 100%; width:100%; z-index: 11;}

.dzu-inputLabel {
    @media (max-width:767px){
        position: static;
    }
}
.dropzone-div{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: opacity .15s linear;
    -webkit-transition: opacity .15s linear;
    @media (min-width:575px) {
        width:calc(100% + 17px);
    }
    .dialog-lg {
        max-width: 1025px;       
        @media (min-width:1024px){
            min-width: 990px;
        }
    }
    .dropzone-dialog {        
        max-width:600px;      
        display: flex;
        align-items: center;
        position: relative;
        width: auto;        
        margin: 1.75rem auto; 
        z-index: 11;
        @media (min-width:575px) {
            min-height: calc(100% - 3.5rem);
        }
        .dropzone-content{
            position: relative;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            width: calc(100% - 20px);
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: .3rem;
            outline: 0;
            margin:0 10px;
            .dropzone-body {
                padding: 1.8rem;
                border-radius: 12px;
                 .d-flex {
                    .form-group {
                        margin: 0;
                    }
                }
                .form-group {
                    label {
                        font-weight:500;
                        margin-bottom: 7px;;
                    }
                }
            }
        }
        .dzu-dropzone {
            border: 2px dashed #F42634;
            border-radius: 12px;
            margin:0 0 15px; 
            display: flex;
            width:100%;            
            flex-direction: row;
            justify-content: space-evenly;
            overflow: hidden;        
            .dzu-submitButtonContainer{
                display: none;
            }
        }
        .flex-column {
            flex-direction: row!important;
            justify-content:flex-end!important;
        }
    }    
    
    .dzu-inputLabel {
        display: flex;
        background-color: transparent;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        font-size: 14px;
        z-index: 1;
        font-weight: 500;
        color: #F42634;
        width: 100%;
        position: static;
        line-height: 18px;
        div{
            label{
            cursor: pointer;
            flex-wrap: wrap;           
            position: absolute;
            z-index: 0;
            font-weight: 300;
            text-align: center;
            font-size: 16px;        
            p{
                margin:0px;
                color:#F42634;
                font-size: 16px;
                span{
                    color:#F42634;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
            span{
                color:#F42634;
                font-size: 16px;
                font-weight: 500;
            }
        }
        cursor: pointer;               
        }
    }
    
    .dzu-inputLabelWithFiles{
        display: flex;
        flex-direction: column;
        text-align:center;
        align-items: center;
        width: 100%;
        justify-content: center;
        color:#82CFF4;
        margin:0;
        background-color: transparent;
        .dzu-input{
            display:block;
            visibility:visible;
            opacity: 0;
            cursor: pointer;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 9;
        }
    }
    .dzu-submitButton{
        display: flex;
        margin: auto !important;
        background-color: transparent;
        border:0px solid transparent;
    }
    .dzu-inputLabelWithFiles{
        display: none;
    }
    textarea {
        min-height: 90px;
    }
}
.dzu-input{
    display:block;
    visibility:visible;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 11;
}

.dzu-dropzone {    
    align-items: start;
    overflow: hidden;
    border-width:1px!important;
    border-radius: 12px;
    padding:15px;
    min-height: 140px;
    .dzu-previewContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding:0;
        width: 100%; 
        z-index: 91;
        border-bottom: 1px solid transparent; 
        box-sizing: border-box;        
        max-width:100%;
        width:100%;        
        .dzu-previewButton{ 
            margin:0;
        }    
       .dzu-previewStatusContainer{
           z-index: 999999 !important;
           .dzu-previewButton{
            z-index: 999999 !important;
 
           }
       }     
    } 
    .dzu-inputLabel {
        margin:0!important;
    }
}
.vechile_pic span {
    position: relative;
}
.vechile_pic span>span {
    position: absolute;
    z-index: 11;
    color: #fff;
    background: #ff0000;
    border-radius: 15px;
    width: 18px;
    height: 18px;
    line-height: 16px;
    right: -2px;
    top: -6px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}
.drag_drop_option {      
     h5 {
        text-align: center;
    }
     @media (max-width:767px){
        flex-direction: column;
     }     
    i {
        margin-right: 15px;
        @media (max-width:767px){
            margin-left: 15px;
            margin-bottom: 15px;
        }
    }      
    p {
        font-weight: 400;
        text-align: center;
    }
}

.dropzone-div .dropzone-dialog .drag_drop_option  {    
    .d-flex {
        flex-direction: column!important;
        @media (max-width:820px){
            margin-top:15px;
        }
    }   
}
.dzu-submitButtonContainer{
    display: flex;
    width: 100%;
    z-index: 99;    
    color: #ffffff;
    .dzu-submitButton{
        margin: auto;
        background: #0091FF;
        border: 1px solid #0091FF;
        padding:5px 17px;
    }
}
.vechile_pic .form-row>span:first-child img {
}

.primary-img{
    border: 2px solid #0091ff;
}
.full{
    padding: 12px 19px;
    border: 2px solid #d9d9d9;
    // border-radius: ;
    border-radius: 12px;
    align-items: start;
    overflow: hidden;
    border-width: 1px !important;
    border-radius: 12px;
    padding: 15px;
    min-height: 140px
}